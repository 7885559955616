import { isAvailable, hasValidToken } from '../generic/idcta/actions';

import {
  FAVOURITE_RECIPE_STATUS,
  REMOVE_FAVOURITE_RECIPE,
  SET_FAVOURITE_RECIPES,
  SET_PAGE_STATE,
  SET_RECIPE_COUNT_PER_PAGE,
  SET_REMOVE_BTN_STATE,
  TOGGLE_CONFIRMATION,
  UPDATE_ADD_TO_FAVOURITES_BUTTON,
  SHOW_FAVOURITES_TOOLTIP,
  HIDE_FAVOURITES_TOOLTIP,
} from './constants';

import { REDIRECT_TO_SIGN_IN } from '../page/constants';

const foodResourceDomain = 'food';
const recipeResourceType = 'recipe';
const favouritedAction = 'favourited';
const globalIdPrefix = `urn:bbc:${foodResourceDomain}:${recipeResourceType}:`;

export const addFavouriteRecipe = recipeId => async (dispatch, getState) => {
  if (!process.env.CLIENT) return;

  const { uas } = getState().pageReducer;

  try {
    await isAvailable();

    if (!(await hasValidToken())) {
      dispatch({
        type: SHOW_FAVOURITES_TOOLTIP,
      });
      return;
    }

    dispatch({
      type: UPDATE_ADD_TO_FAVOURITES_BUTTON,
      favouriteRecipeStatus: FAVOURITE_RECIPE_STATUS.ADDING,
    });

    const res = await fetch(`${uas.url}my/favourites`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': uas.apiKey,
        'X-Authentication-Provider': 'idv5',
      },
      credentials: 'include',
      body: JSON.stringify({
        resourceDomain: foodResourceDomain,
        resourceType: recipeResourceType,
        resourceId: recipeId,
        action: favouritedAction,
      }),
    });

    switch (res.status) {
      case 202:
        dispatch({
          type: UPDATE_ADD_TO_FAVOURITES_BUTTON,
          favouriteRecipeStatus: FAVOURITE_RECIPE_STATUS.ADDED,
        });
        return;
      case 401:
        dispatch({
          type: REDIRECT_TO_SIGN_IN,
        });
        return;
      default:
        throw new Error(`Status ${res.status}`);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`[Favourites] Failed to add recipe to favourites`, err);
    dispatch({
      type: UPDATE_ADD_TO_FAVOURITES_BUTTON,
      favouriteRecipeStatus: FAVOURITE_RECIPE_STATUS.ERROR_ADDING,
    });
  }
};

export const getFavouriteRecipe = recipeId => async (dispatch, getState) => {
  if (!process.env.CLIENT) return;

  const { uas } = getState().pageReducer;

  try {
    await isAvailable();

    if (!(await hasValidToken())) {
      dispatch({
        type: UPDATE_ADD_TO_FAVOURITES_BUTTON,
        favouriteRecipeStatus: FAVOURITE_RECIPE_STATUS.NOT_FAVOURITED,
      });
      return;
    }

    const res = await fetch(
      `${uas.url}my/favourites/${encodeURIComponent(globalIdPrefix + recipeId)}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': uas.apiKey,
          'X-Authentication-Provider': 'idv5',
        },
        credentials: 'include',
      }
    );

    switch (res.status) {
      case 200: {
        const body = await res.json();
        if (body.action === 'favourited') {
          dispatch({
            type: UPDATE_ADD_TO_FAVOURITES_BUTTON,
            favouriteRecipeStatus: FAVOURITE_RECIPE_STATUS.ADDED,
          });
          return;
        }
        dispatch({
          type: UPDATE_ADD_TO_FAVOURITES_BUTTON,
          favouriteRecipeStatus: FAVOURITE_RECIPE_STATUS.NOT_FAVOURITED,
        });
        return;
      }
      case 204:
      case 401:
        dispatch({
          type: UPDATE_ADD_TO_FAVOURITES_BUTTON,
          favouriteRecipeStatus: FAVOURITE_RECIPE_STATUS.NOT_FAVOURITED,
        });
        return;
      default:
        throw new Error(res.status);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`[Favourites] Failed to check if recipe is favourite`, err);
    dispatch({
      type: UPDATE_ADD_TO_FAVOURITES_BUTTON,
      favouriteRecipeStatus: FAVOURITE_RECIPE_STATUS.NOT_FAVOURITED,
    });
  }
};

export const getFavouriteRecipes = pageIndex => async dispatch => {
  if (!process.env.CLIENT) return;

  try {
    await isAvailable();

    if (!(await hasValidToken())) {
      dispatch({
        type: REDIRECT_TO_SIGN_IN,
      });
      return;
    }

    const res = await fetch(`/food/favourites/recipes/${pageIndex}`, {
      credentials: 'include',
    });

    switch (res.status) {
      case 200: {
        const favouriteRecipes = await res.json();

        dispatch({
          type: SET_FAVOURITE_RECIPES,
          favouriteRecipes: favouriteRecipes.recipes,
          total: favouriteRecipes.total,
          page: pageIndex,
        });

        return;
      }
      case 401:
        dispatch({
          type: REDIRECT_TO_SIGN_IN,
        });
        return;
      default:
        throw new Error(res.status);
    }
  } catch (e) {
    dispatch({
      type: SET_PAGE_STATE,
      state: 'error',
    });
  }
};

export const deleteFavouriteRecipe = id => async (dispatch, getState) => {
  if (!process.env.CLIENT) return;

  const { uas } = getState().pageReducer;

  try {
    await isAvailable();

    if (!(await hasValidToken())) {
      dispatch({
        type: REDIRECT_TO_SIGN_IN,
      });
    }
    dispatch({
      type: SET_REMOVE_BTN_STATE,
      state: 'waiting',
      id,
    });

    const res = await fetch(
      `${uas.url}my/favourites/urn:bbc:${foodResourceDomain}:${recipeResourceType}:${id}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': uas.apiKey,
          'X-Authentication-Provider': 'idv5',
        },
        credentials: 'include',
      }
    );

    switch (res.status) {
      case 202:
        dispatch({
          type: REMOVE_FAVOURITE_RECIPE,
          id,
        });
        return;
      case 401:
        dispatch({
          type: REDIRECT_TO_SIGN_IN,
        });
        return;
      default:
        throw new Error(res.status);
    }
  } catch (e) {
    dispatch({
      type: SET_REMOVE_BTN_STATE,
      state: 'error',
      id,
    });
  }
};

export const toggleConfirmation = id => ({
  type: TOGGLE_CONFIRMATION,
  id,
});

export const setRecipeCountPerPage = count => ({
  type: SET_RECIPE_COUNT_PER_PAGE,
  count,
});

export const closeTooltip = () => ({
  type: HIDE_FAVOURITES_TOOLTIP,
});
