import { IDCTA_INIT_RENDER, IDCTA_FAIL_RENDER, IDCTA_FLAGPOLE_RED } from './constants';

const IDCTA_UNAVAILABLE_MSG = '[IDCTA]: Service unavailable | Flagpole is RED';

export const isAvailable = () =>
  window.require
    ? new Promise((resolve, reject) =>
        window.require(['idcta/id-config'], async idcta => {
          const config = await idcta.getConfigAsync();
          return config['id-availability'] === 'GREEN'
            ? resolve(true)
            : reject(IDCTA_UNAVAILABLE_MSG);
        })
      )
    : Promise.reject(IDCTA_UNAVAILABLE_MSG);

export const getIdctaInit = idctaPath => async dispatch => {
  if (!process.env.CLIENT) return;

  try {
    await isAvailable();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`[Favourites] Failed to fetch Init module from IDCTA`, err);
    dispatch({
      type: IDCTA_FLAGPOLE_RED,
      idctaFlagpoleRed: true,
    });
  }

  try {
    const idctaInit = await fetch(idctaPath);

    if (idctaInit.status !== 200) {
      throw new Error(`Invalid Status Code: ${idctaInit.status}`);
    }

    dispatch({
      type: IDCTA_INIT_RENDER,
      idctaInit: await idctaInit.json(),
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`[Favourites] Failed to fetch Init module from IDCTA`, err);
    dispatch({
      type: IDCTA_FAIL_RENDER,
      idctaInit: { error: true },
    });
  }
};

export const hasValidToken = () =>
  window.require
    ? new Promise((resolve, reject) =>
        window.require(
          ['idcta/idcta-1'],
          async idcta => {
            if (idcta.hasCookie()) {
              try {
                await idcta.initiateTokenRefresh();
                return resolve(true);
              } catch (e) {
                return resolve(false);
              }
            }
            return resolve(false);
          },
          reject
        )
      )
    : Promise.resolve(true);
